<script setup lang="ts">
import { getNode } from '@formkit/core'
import type { FormKitNode } from '@formkit/core'
import type { SubmitButtonProps } from './SubmitButton.props'

const props = defineProps<SubmitButtonProps>()

const node: FormKitNode = getNode(props.formId)!

const { isLoading, success } = useFormKitState([props.formId])

const submitCurrentForm = async () => {
  node?.submit()
  if (!node?.context?.state?.valid) {
    scrollToErrors(node)
  }
}
</script>

<template>
  <Transition name="hide-bar">
    <div
      v-if="isOtpValid || !isRegistration"
      class="bg-primitives-white z-10 w-full text-center"
    >
      <AtomsCta
        :full-width="true"
        :button-attrs="{ type: 'submit' }"
        class="mx-auto md:w-fit"
        :loading="isLoading"
        :success="success"
        @click-handler="submitCurrentForm"
      >
        <template #label>
          {{
            isRegistration
              ? $ts('myAccount.registration.ctaSignUp')
              : $ts('myAccount.login.loginBtn')
          }}
        </template>
      </AtomsCta>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
/* HIDE STRIP */
.hide-bar-enter-active,
.hide-bar-leave-active {
  transition: all 0.3s ease;
}

.hide-bar-enter-from,
.hide-bar-leave-to {
  @apply translate-y-full;
}
</style>
